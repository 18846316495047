<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<!-- 搜索框 -->
			<el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small" @submit.native.prevent>
				<el-form-item label="名称" label-width="50px" prop="keyword">
					<el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable
						style="width: 230px" @keyup.enter.native="query"/>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
					<el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<el-button type="primary" icon="el-icon-plus" size="mini" @click.stop="handleAdd">新增</el-button>
				<el-button type="primary" icon="el-icon-download" size="mini"
					@click.stop="downloadTemplate">下载模板</el-button>
				<el-upload class="upload-demo d-inline-block" ref="upload" action="#" :on-change="importExcel"
					:on-success="importSuccess" :on-error="importError" :before-upload="beforeImportUpload" :limit="1"
					:multiple="false" :show-file-list="false" accept=".xls, .xlsx" :auto-upload="false">
					<el-button slot="trigger" icon="el-icon-upload2" size="small" type="primary">导入</el-button>
				</el-upload>
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
			<!--表格-->
			<el-table v-loading="loading" size="small" :data="list" element-loading-text="Loading" highlight-current-row
				style="width: 100%">
				<el-table-column label="序号" width="80" align="center">
					<template slot-scope="scope">
						{{ (queryForm.page - 1) * queryForm.limit + scope.$index + 1 }}
					</template>
				</el-table-column>
				<el-table-column label="名称" min-width="110" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.name }}</span>
					</template>
				</el-table-column>
				<el-table-column label="法人" align="center" prop="legal_person"></el-table-column>
				<el-table-column label="手机" min-width="110" align="center" prop="l_mobile"></el-table-column>
				<el-table-column label="联系人" min-width="110" align="center" prop="work_person"></el-table-column>
				<el-table-column label="联系人手机" min-width="110" align="center" prop="work_mobile"></el-table-column>
				<el-table-column label="地址" min-width="110" align="center" prop="address"></el-table-column>
				<el-table-column label="等级" min-width="110" align="center" prop="grade_name"></el-table-column>
				<el-table-column label="状态" min-width="60" align="center">
					<template slot-scope="scope">
						<el-tag v-if="scope.row.status === 1" size="mini" type="success">正常</el-tag>
						<el-tag v-if="scope.row.status === 2" size="mini" type="danger">停用</el-tag>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="utime" label="时间" min-width="170">
					<template slot-scope="scope">
						<i class="el-icon-time" />
						<span>{{ scope.row.utime }}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" min-width="100" align="center" fixed="right">
					<template slot-scope="scope">
						<el-tooltip class="item" effect="dark" content="编辑" placement="top">
							<el-button type="success" icon="el-icon-edit" size="mini" circle
								@click.stop="handleEdit(scope.row)"></el-button>
						</el-tooltip>
						<el-tooltip class="item" effect="dark" content="删除" placement="top">
							<el-button type="danger" icon="el-icon-delete" size="mini" circle
								@click.stop="handleDelete(scope.row.id)"></el-button>
						</el-tooltip>
					</template>
				</el-table-column>
			</el-table>
			<pagination :total="total" auto-scroll :page.sync="queryForm.page" :limit.sync="queryForm.limit"
				@pagination="query" />
		</el-card>
	</div>
</template>

<script>
import GlobalTips from "@/components/GlobalTips";
import common from "@/common/mixins/common.js"
export default {
	inject: ['app'],
	mixins: [common],
	components: {
		GlobalTips,
	},
	data() {
		return {
			preUrl: 'user',
			// autoRequest: false,
			queryForm: {
				page: 1,
				limit: 10,
				grade: 98,
				keyword: '',
			},
		}
	},
	mounted() {
	},
	methods: {
		
		handleAdd() {
			this.$router.push({
				path: "/user_edit",
				query: {
					grade: 98
				}
			})
		},
		handleEdit(row) {
			this.$router.push({
				path: "/user_edit",
				query: {
					grade: 98,
					user: JSON.parse(JSON.stringify(row))
				}
			})
		},
		downloadTemplate() {
			this.axios.get('/manage/user/downloadLsTemplate', {
				responseType: 'arraybuffer',
			}).then(res => {
				const aLink = document.createElement("a");
				let blob = new Blob([res], { type: "application/vnd.ms-excel" });
				aLink.href = URL.createObjectURL(blob);
				aLink.download = '导入模板.xlsx';
				aLink.click();
				document.body.appendChild(aLink);
			})
		},
		importExcel(e) {
			//获取用户选择的文件
			const file = e.raw
			let formdata = new FormData()
			formdata.append('file', file)
			this.axios.post('/manage/user/importLsExport', formdata).then(res => {
				this.$refs.upload.clearFiles()
				if (res.status) {
					this.$message.success('导入成功')
					this.getList()
				} else {
					this.$message.success('导入失败')
				}

			})
		},
		beforeImportUpload(file) {
			let fileArr = file.name.split('.')
			let suffix = fileArr[fileArr.length - 1]
			//只能导入.xls和.xlsx文件
			if (!/(xls|xlsx)/i.test(suffix)) {
				this.$message('文件格式不正确')
				return false
			}
			return true
		},
		importSuccess(e) {
			console.log(e)
			// 上传成功之后清除历史记录;否则只能上传一次
			this.$refs.upload.clearFiles()
		},
		importError(e) {
			console.log(e)
			this.$refs.upload.clearFiles()
		},
	}
}
</script>

<style></style>